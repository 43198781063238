<template>
  <a-drawer
      :zIndex="1000"
      :title="editobj.name"
      :width="'650px'"
      @close="onClose"
      :visible="visible"
      :drawerStyle="{height: 'calc(100% - 108px)'}"
    >
    <bh-loading :show="loading" />

    <template v-if="editDialogOBJ.categoryId">

      <p :style="pStyle" v-if="editDialogOBJ.source && editDialogOBJ.source == 'google'">Info</p>
        <a-form-model :model="editDialogOBJ" ref="editDialogOBJ">
          <a-row :gutter="16">
            <a-col :span="12">
                <a-form-model-item :rules="req('Please enter the Marker Name')" required prop="name" label="Name">
                  <a-input v-model="editDialogOBJ.name" placeholder="Enter your marker name" >
                  </a-input>
                </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="editDialogOBJ.source && editDialogOBJ.source == 'google'">
              <strong class="block">Rating</strong>
              <a-rate :value="editDialogOBJ.rating" allow-half :disabled="true" />
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item prop="address" label="Address">
                <a-input v-model="editDialogOBJ.formatted_address" placeholder="Address" >
                </a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <template v-if="editDialogOBJ.source && editDialogOBJ.source == 'google' || editDialogOBJ.source == 'bildhive'">
          <a-divider />
            <a-row>
                <a-form-item label="Image">
                  <marker-image-selector :img="editDialogOBJ.image" @callback="updateMarkerImage"></marker-image-selector>
                </a-form-item>
            </a-row>
          <div class="source" v-if="editDialogOBJ.source == 'google'">
              <small>Sourced from Google &copy; </small>
          </div>
        </template>
        <!-- <a-row v-else class="mt-4">
          <a-col :span="12" >
            <strong class="block">Lat / Lng</strong>
            <p>
              Latitude: {{editDialogOBJ.geometry.location.lat}}<br/>
              Longitude: {{editDialogOBJ.geometry.location.lng}}
            </p>
          </a-col>
        </a-row> -->
        <a-divider/>
        <a-row class="mt-4">
          <a-col :span="12" >
            <p>Choose Category</p>
            <a-select v-model="editDialogOBJ.categoryId" class="w-full">
              <template v-for="cat in categories">
                <a-select-option v-if="cat.id !== '__pinnedMarkers2'" :value="cat.id" :key="cat.id">{{cat.name}}</a-select-option>
              </template>
            </a-select>
          </a-col>
        </a-row>
		<a-row v-if="editDialogOBJ.categoryId === '__pinnedMarkers'" class="mt-4">
		  <!-- <a-col :span="12">
			<a-form-item label="Image">
				<marker-image-selector :img="editDialogOBJ.image" @callback="updateMarkerImage"></marker-image-selector>
			</a-form-item>
		  </a-col> -->
		  <a-col :span="12">
			<a-form-item>
				<div @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerIcon,type:'images'})" style="margin-top: 2em;" class="edit-current-marker"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>
        <div class="dF aC" style="line-height: 2;">
          Marker Icon
          <a-tooltip class="ml-1" overlayClassName="change-tooltip-color">
              <template slot="title">
                  For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
              </template>
              <a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
          </a-tooltip>
        </div>
				<marker-selector @callback="updateMarkerIcon">
				<div class="image-holder-box">
					<span class="content">
					<div class="inside" :style="{ 'background-image': 'url(' + editDialogOBJ.icon + ')' }" />
					</span>
				</div>
				</marker-selector>

				<!-- <a-button type="primary" class="mt-3" @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerIcon,type:'images'})">Select from File Manager </a-button> -->
			</a-form-item>
		  </a-col>
        </a-row>
    </template>


    <!-- Developer Marker Edit -->
    <template v-else-if="editDialogOBJ.id === '__dev'">
      <a-row :gutter="16">
          <a-col :span="24">
            <a-form-model-item label="Development Name">
              <a-input size="large" v-model="editDialogOBJ.devName" placeholder="Enter your Development Name" >
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
			  <div @click="$store.commit('MEDIA_SELECT',{callback:selectedImg,type:'images'})" style="top: 30px;" class="edit-current-marker mt-3"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>
              <div class="dF aC" style="line-height: 2;">
                Development Marker Icon
                <a-tooltip class="ml-1" overlayClassName="change-tooltip-color">
                    <template slot="title">
                        For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
                    </template>
                    <a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
                </a-tooltip>
              </div>
              <marker-selector @callback="updateDevLogo" class="mt-3">
                <div class="image-holder-box">
                  <span class="content">
                    <div class="inside" :style="{ 'background-image': 'url(' + devEdit.logo + ')' }" />
                  </span>
                </div>
              </marker-selector>

              <!-- <a-button type="primary" class="mt-3" @click="$store.commit('MEDIA_SELECT',{callback:selectedImg,type:'images'})">Select from File Manager </a-button> -->
            </a-form-item>
          </a-col>
      </a-row>
      <a-row :gutter="16">
        <!-- <a-col :span="9">
            <a-form-item label="Latitude">
              <a-input v-model="devEdit.lat" placeholder="Address (optional)" >
              </a-input>
            </a-form-item>
        </a-col>
        <a-col :span="9">
            <a-form-item label="Longitude">
              <a-input v-model="devEdit.lng" placeholder="Address (optional)" >
              </a-input>
            </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="a" class="hidden-label">
              <a-button type="primary" @click.native="putMarker(devEdit.lat,devEdit.lng)">Update Marker</a-button>
          </a-form-item>
        </a-col> -->
        <a-col :span="24">
          <a-form-item label="Search places from Google">
            <a-auto-complete
              @select="selectedDev"
              :value="placeSelected"
              @input.native="$event => googleQuery = $event.target.value "
              class="certain-category-search"
              dropdownClassName="certain-category-search-dropdown"
              :dropdownMatchSelectWidth="false"
              :dropdownStyle="{width: '300px'}"
              size="large"
              style="width: 100%"
              placeholder="Enter address or location name here"
              optionLabelProp="value"
            >
            <template slot="dataSource">
              <a-select-option v-for="opt in dataSource" :key="opt.place_id" :value="`${opt.place_id}`">
                {{opt.main_text}}<br/>
                <span class="certain-search-item-count">{{opt.secondary_text}}</span>
              </a-select-option>
            </template>
              <a-input>
                <a-icon slot="suffix" type="search" class="certain-category-icon" />
              </a-input>
            </a-auto-complete>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>

        <a-col :span="24">
          <div id="dev-map" style="width:100%; height:500px;"></div>
        </a-col>
      </a-row>

      <h4 class="mt-5">Map theme color</h4>
      <a-checkbox value="1" @change="onChange" :checked="checkedList.includes('1')" class="theme-check-box">
          <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/rastertiles/voyager/1/0/0.png');" />
          Default
      </a-checkbox>

      <a-checkbox value="2" @change="onChange" :checked="checkedList.includes('2')" class="theme-check-box">
          <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/light_all/1/0/0.png');" />
          Light
      </a-checkbox>

      <a-checkbox value="3" @change="onChange" :checked="checkedList.includes('3')" class="theme-check-box">
          <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/dark_all/1/0/0.png');" />
          Dark
      </a-checkbox>

      <a-checkbox value="4" @change="onChange" :checked="checkedList.includes('4')" class="theme-check-box">
          <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/rastertiles/voyager/1/0/0.png');" />
          Custom
      </a-checkbox>

      <div v-if="checkedList.includes('4')" class="mt-5">
        <h5 class="mb-3">Choose a color</h5>
        <a-radio-group v-model="tempCSS" @change="onAfterChange" button-style="solid">
          <a-radio-button :value="60">
            Green
          </a-radio-button>
          <a-radio-button :value="130">
            Blue
          </a-radio-button>
          <a-radio-button :value="270">
            Red
          </a-radio-button>
        </a-radio-group>
      </div>

      <!-- <div v-if="checkedList.includes('4')" class="dF mt-5">
          <a-slider :default-value="0" :min="0" :max="360" style="width: 75%;" @afterChange="onAfterChange" />
          <a-button @click="updateCSS">Update</a-button>
      </div> -->
    </template>


    <!-- Category Marker Edit -->
    <template v-else>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-model :model="editDialogOBJ" ref="editDialogOBJ">
              <a-form-model-item :rules="req('Please enter the Category Name')" required prop="name" label="Category Title">
                <a-input v-model="editDialogOBJ.name" placeholder="Enter your category name" >
                </a-input>
              </a-form-model-item>
            </a-form-model>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="Description">
            <a-textarea
            :rows="4"
            placeholder="Please enter a description about this Category. This could help in publishing your amenities in different ways."
            v-model="editDialogOBJ.description"
            required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item>
            <!-- <div @click="$store.commit('MEDIA_SELECT',{callback:selectedDialogImg,type:'images'})" style="top: 30px;" class="edit-current-marker mt-3"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>  -->
            <div @click="$store.commit('MEDIA_SELECT',{callback:selectedDialogImg,type:'images'})" style="margin-top: 2em;" class="edit-current-marker"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>
            <div class="dF aC" style="line-height: 2;">
              Marker Icon
              <a-tooltip class="ml-1" overlayClassName="change-tooltip-color">
                  <template slot="title">
                      For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
                  </template>
                  <a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
              </a-tooltip>
            </div>
            <marker-selector @callback="updateMarkerIcon">
            <div class="image-holder-box">
              <span class="content">
              <div class="inside" :style="{ 'background-image': 'url(' + editDialogOBJ.icon + ')' }" />
              </span>
            </div>
            </marker-selector>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
						<a-col :span="12">
							<h5>Types</h5>
							<p>Sourced from Google</p>
						</a-col>
					</a-row>
					<a-checkbox-group v-model="editDialogOBJ.value">
						<a-row type="flex">
							<a-col class="mb-4" :span="8" v-for="(cat,catI) in allCats" :key="cat+catI">
                <div style="font-weight: 600;">{{catI}}</div>
                <template v-for="(aCat,aCatI) in cat">
                  <a-checkbox class="w-full" style="margin-left: 0;" :value="aCat" :key="aCat+aCatI">
                    {{aCat[0].toUpperCase() + aCat.split('_').join(' ').slice(1)}}
                  </a-checkbox>
                </template>
							</a-col>
						</a-row>
					</a-checkbox-group>
    </template>

    <form-submit :edit="true" @submit="confirmEdit" @cancel="onClose">
	</form-submit>
    </a-drawer>
</template>

<script>
  import {markerIcon} from 'bh-mod'
  import bhLoading from 'bh-mod/components/common/Loading'
  import * as maptalks from 'maptalks';
  import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
  import MarkerSelector from '@/components/amenities/MarkerSelector'
  import MarkerImageSelector from '@/components/amenities/MarkerImageSelector'
  import FormSubmit from '@/components/amenities/FormSubmit'
  export default {
    components:{bhLoading,ImageBoxSelector,MarkerSelector,MarkerImageSelector,FormSubmit},
    data() {
      return {
        tempCSS:'',
        theme:'',
        map:null,
        vectorLayer:null,
        devEdit:{
          name:'',
          logo:'',
          lat:'',
          lng:'',
        },
        amenityOBJ:{
          name:'',
          lat:null,
          lng:null,
          logo:'https://cdn.bildhive.com/96c8782107aa4021b5bb02f4f50c2aa6.png',
          theme:{
              type:'',
              cssFilter:0,
          },
        },
        checkedList:[],
        loading:false,
        form: this.$form.createForm(this),
        deletePopup:false,
        deleting:false,
        googleQuery:'',
        dataSource:[],
        processingGoogle:false,
        placeSelected:'',
        placeChosen:false,
        marker:{
          name:'',
          categoryId:null,
          geometry:{
            location:{
              lat:null,
              lng:null
            }
          }
        },
        category:{},
        editDialogOBJ:{
          name:'',
          icon:'',
          description:'',
          devName:''
        },
        pStyle: {
          fontSize: '16px',
          color: 'rgba(0,0,0,0.85)',
          lineHeight: '24px',
          display: 'block',
          marginBottom: '16px',
        },
        pStyle2: {
          marginBottom: '24px',
		},
		showOptions: false,
    allCats:{
          "Commute":[
            'airport',
            'bus_station',
            'light_rail_station',
            'subway_station',
            'taxi_stand',
            'train_station',
            'transit_station'
          ],
          "Food & Drink":[
            'bakery',
            'bar',
            'cafe',
            'liquor_store',
            'meal_delivery',
            'meal_takeaway',
            'restaurant',
            'supermarket'
          ],
          "Health & Personal Care":[
            'beauty_salon',
            'dentist',
            'doctor',
            'gym',
            'hair_care',
            'hospital',
            'pharmacy',
            'physiotherapist',
            'spa',
            'veterinary_care'
          ],
          "Places of Worship":[
            'cemetery',
            'church',
            'hindu_temple',
            'mosque',
            'synagogue'
          ],
          "Public Services":[
            'city_hall',
            'courthouse',
            'embassy',
            'fire_station',
            'library',
            'local_government_office',
            'police',
            'post_office'
          ],
          "Schools":[
            'primary_school',
            'school',
            'secondary_school',
            'university'
          ],
          "Recreation & Entertainment":[
            'amusement_park',
            'aquarium',
            'art_gallery',
            'bowling_alley',
            'campground',
            'casino',
            'movie_rental',
            'movie_theater',
            'museum',
            'night_club',
            'park',
            'rv_park',
            'stadium',
            'tourist_attraction',
            'zoo'
          ],
          "Services":[
            'accounting',
            'atm',
            'car_rental',
            'car_repair',
            'car_wash',
            'electrician',
            'funeral_home',
            'gas_station',
            'insurance_agency',
            'laundry',
            'lawyer',
            'locksmith',
            'lodging',
            'moving_company',
            'painter',
            'parking',
            'plumber',
            'real_estate_agency',
            'roofing_contractor',
            'storage',
            'travel_agency',
          ],
          "Shopping":[
            'bicycle_store',
            'book_store',
            'car_dealer',
            'clothing_store',
            'convenience_store',
            'department_store',
            'drugstore',
            'electronics_store',
            'florist',
            'furniture_store',
            'hardware_store',
            'home_goods_store',
            'jewelry_store',
            'pet_store',
            'shoe_store',
            'shopping_mall',
            'store'
          ],

        },
      };
    },
    watch:{
        editobj(val){
            if (val.id){
              console.log('VALLLLLL', val)
              this.editDialogOBJ = JSON.parse(JSON.stringify(this.editobj))
              this.editDialogOBJ.devName = this.appData.name
              console.log('EDITDIALOGOBJ', this.editDialogOBJ)
              if (this.editDialogOBJ.categoryId || this.editDialogOBJ.id === '__dev'){
                console.log('DEV OR MARKER CLICKED')
              } else {
                console.log('CATEGORY CLICKED')
              }
              // if (!obj.icon) obj.icon = markerIcon
              // this.editDialogOBJ = obj
            }
        },
        googleQuery(val){
        if (val.includes('plid___')) {
          console.log('HEY TRANFSFORM ING DATA')
          let id = val.split('plid___')[1]
          this.placeSelected = this.dataSource.find(x => x.place_id === id).main_text
        } else {
          this.placeSelected = val
        }
        if (val.length > 3){
          this.processingGoogle = true
          console.log('ALKJSDFLKJSDF', val)
          // /amenities?input=boston pizzza&lat=43.8085448&lng=-79.4844136
          let {lat,lng} = this.appData
          this.$api.get(`/amenities/?input=${val}&lat=${lat}&lng=${lng}`).then( ({data}) => {
            console.log('RETURNING DATA FROM PLACES AUTO CPOMPLETE', data)
            this.dataSource = data
            if (this.$store.state.plotCategory !== '__dev') this.marker.categoryId = this.$store.state.plotCategory
            else this.marker.categoryId = this.categories[0].id
          }).catch( err => {
            return null
          })

        } else {
          this.processingGoogle = false
          this.dataSource = []
        }
      },
        visible(val){

            if (val){
              if (this.editobj.id === '__dev'){
                console.log('APP DATAAA', this.appData)
                let self = this

                let {lat,lng,logo} = this.appData

                this.devEdit = {lat,lng,logo}

                if (self.map) {
                  self.map.remove()
                  this.theme = ''
                }
                if (this.mapTheme.cssFilter != 0){
                  this.checkedList = ['4']
                  this.tempCSS = this.mapTheme.cssFilter
                } else {
                  if (this.mapTheme.type == 'rastertiles/voyager'){
                    this.checkedList = ['1']
                  } else if (this.mapTheme.type == 'light_all'){
                    this.checkedList = ['2']
                  } else {
                    this.checkedList = ['3']
                  }
                }
                console.log('THIS MAPTHEME', this.mapTheme)
                console.log('CHECKED LISTTT', this.checkedList)

                setTimeout(() => {
                  this.amenityOBJ.theme.type = this.mapTheme.type
                  this.amenityOBJ.theme.cssFilter = this.mapTheme.cssFilter
                  if (this.mapTheme.cssFilter != 0){
                    this.theme = `2http://{s}.basemaps.cartocdn.com/${this.mapTheme.type}/{z}/{x}/{y}.png`
                  } else {
                    this.theme = `http://{s}.basemaps.cartocdn.com/${this.mapTheme.type}/{z}/{x}/{y}.png`
                  }
                }, 1000);
              }
            } else {
              this.deleting = false
              this.deletePopup = false
              this.loading = false
              this.placeSelected = ''
            }

        },
        theme(val){
            if (val != ''){
              console.log('NEW AMENITYOBJ THEME', this.amenityOBJ.theme.type)
              this.tempCSS = 0
              if (this.editobj.id === '__dev'){
                if (val && this.theme.substring(0,1) != '2'){
                    let self = this

                    let {lat,lng,logo} = this.appData
                    this.devEdit = {lat,lng,logo}

                    setTimeout(() => {

                    self.map = new maptalks.Map('dev-map', {
                        center : [lng,lat],
                        zoom: 12,
                        dragPitch: true,
                        dragRotate: true,
                        minZoom: 12,
                        maxZoom: 16,
                        dragRotatePitch: true,
                        baseLayer: new maptalks.TileLayer('base', {
                            urlTemplate: `http://{s}.basemaps.cartocdn.com/${this.amenityOBJ.theme.type}/{z}/{x}/{y}.png`,
                            subdomains: ['a', 'b', 'c', 'd'],
                            attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
                            cssFilter : `hue-rotate(${this.amenityOBJ.theme.cssFilter}deg)`
                        })
                    })
                    }, 100);
                }
                else if (val && this.theme.substring(0,1) == '2'){
                    let self = this

                    let {lat,lng,logo} = this.appData
                    this.devEdit = {lat,lng,logo}

                    setTimeout(() => {

                    self.map = new maptalks.Map('dev-map', {
                        center : [lng,lat],
                        zoom: 12,
                        dragPitch: true,
                        dragRotate: true,
                        minZoom: 12,
                        maxZoom: 16,
                        dragRotatePitch: true,
                        baseLayer: new maptalks.TileLayer('base', {
                            urlTemplate: `http://{s}.basemaps.cartocdn.com/${this.amenityOBJ.theme.type}/{z}/{x}/{y}.png`,
                            subdomains: ['a', 'b', 'c', 'd'],
                            attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
                            cssFilter : `hue-rotate(${this.amenityOBJ.theme.cssFilter}deg)`
                        })
                    })
                    }, 100);
                }
                if (this.devEdit.lat !== '' && this.devEdit.lng !== ''){
                    setTimeout(() => {
                        this.putMarker(this.devEdit.lat,this.devEdit.lng)
                        this.changeView(this.devEdit.lat,this.devEdit.lng)
                    }, 100);
                }
              }
            }
        },
    },
    computed:{
        mapTheme(){
          return this.$store.state.appData.theme
        },
        categories(){
          return [...Object.values(this.$store.state.amenityCategories)]
        },
        getDevIcon(){
          return this.devEdit.logo
        },
        getIcon(){
          return this.editDialogOBJ.icon || markerIcon
        },
        editobj(){
          return this.$store.state.editOBJ
        },
        visible(){
          return this.$store.state.editDialog || false
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.appData
        },
    },
    methods: {
      req:msg=>({required:true,message:msg}),
      updateCSS(){
          console.log('UPDATE CSS CLICKED', this.tempCSS)

          if (this.amenityOBJ.theme.cssFilter != this.tempCSS){
              this.amenityOBJ.theme.cssFilter = this.tempCSS
              this.map.remove()
              let self = this

              let {lat,lng,logo} = this.appData
              this.devEdit = {lat,lng,logo}

              setTimeout(() => {
              console.log('SELF THEME SUB 1', self.theme.substring(1))

              self.map = new maptalks.Map('dev-map', {
                  center : [lng,lat],
                  zoom: 12,
                  dragPitch: true,
                  dragRotate: true,
                  minZoom: 12,
                  maxZoom: 16,
                  dragRotatePitch: true,
                  baseLayer: new maptalks.TileLayer('base', {
                      urlTemplate: self.theme.substring(1),
                      subdomains: ['a', 'b', 'c', 'd'],
                      attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
                      cssFilter : `hue-rotate(${this.tempCSS}deg)`
                  })
              })
              }, 200);

              if (this.devEdit.lat !== '' && this.devEdit.lng !== ''){
                  setTimeout(() => {
                      this.putMarker(this.devEdit.lat,this.devEdit.lng)
                      this.changeView(this.devEdit.lat,this.devEdit.lng)
                  }, 200);
              }
          }
      },
      onAfterChange(e) {
          console.log('afterChange: ', e.target.value);
          // this.tempCSS = e.target.value
          // console.log('afterChange: ', e);
          // this.tempCSS = e
          this.updateCSS()
      },
      onChange(e) {
          if (!this.checkedList.includes(e.target.value)){
              this.checkedList = [e.target.value]
              this.map.remove()
              if (e.target.value == '1'){
                  this.theme = 'http://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
                  this.amenityOBJ.theme.type = 'rastertiles/voyager'
                  this.amenityOBJ.theme.cssFilter = 0
              } else if (e.target.value == '2'){
                  this.theme = 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
                  this.amenityOBJ.theme.type = 'light_all'
                  this.amenityOBJ.theme.cssFilter = 0
              } else if (e.target.value == '3'){
                  this.theme = 'http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
                  this.amenityOBJ.theme.type = 'dark_all'
                  this.amenityOBJ.theme.cssFilter = 0
              } else {
                  this.theme = '2http://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
                  this.amenityOBJ.theme.type = 'rastertiles/voyager'
                  this.amenityOBJ.theme.cssFilter = 0
              }
          }

      },

      selectedMarkerIcon(url){
        console.log('url', url)
        this.editDialogOBJ.icon = url.url
      },
      selectedMarkerImage(url){
        this.editDialogOBJ.image = url.url
      },
      updateMarkerIcon(data){
      this.editDialogOBJ.icon = data
      },
      updateMarkerImage(data){
        this.editDialogOBJ.image = data
      },

      selectedImg(url){
        console.log('SELECTED IMAGE',url)
        this.devEdit.logo = url.url
        // console.log('devedit', this.devEdit.logo)
      },
      updateDevLogo(data){
        console.log('SOME DATAAA here ->', data)
        this.devEdit.logo = data
      },
      selectedDialogImg(url){
        console.log('SELECTED DIALOG IMAGE',url)
        this.editDialogOBJ.icon = url.url
      },
      updateDialogOBJLogo(data){
        console.log('SOME DATAAA Dialog here ->', data)
        if (data.hasOwnProperty('url')) {
          this.editDialogOBJ.icon = data.url
        } else {
          this.editDialogOBJ = data
        }
      },
      changeView(latitude,longitude) {
          this.map.animateTo({
          center: [longitude, latitude],
          zoom: 15,
          pitch: 0,
          bearing: 20
          }, {
          duration: 1000
          });
          // setTimeout(() => {

          // }, 4000);
      },
      putMarker(latitude,longitude){

        let self = this
        if (this.vectorLayer) this.map.removeLayer(this.vectorLayer);

        this.vectorLayer = new maptalks.VectorLayer('vector',{
          enableAltitude : true,        // enable altitude
          altitudeProperty : 'altitude' // altitude property in properties, default by 'altitude'
        }).addTo(this.map);

        this.point = new maptalks.Marker(
            [longitude, latitude],
            {
              draggable : true,
              properties : {
                altitude : 5
              }
            }
        );



        this.point.addTo(this.vectorLayer);

        this.point.setInfoWindow({
          //'autoOpenOn' : 'click',  //set to null if not to open window when clicking on map
          'single' : false,
          'width'  : 183,
          'height' : 105,
          'custom' : true,
          'dx' : -3,
          'dy' : -12,
          'content'   : '<div class="pop__content">' +
            '<div class="pop__title">Lat/Lng Info </div>' +
            `<div class="pop__time popup-lat-lng"> <span id="popup-lat">${latitude}</span> <br/><span id="popup-lng">${longitude}</span></div>` +
            '</div>'
        });

        this.devEdit = {
          ...this.devEdit,
          lat:latitude,
          lng:longitude
        }

        this.point.openInfoWindow();


        // this.point.on('mouseenter', function (e) {
        //   console.log('e',e)
        // })
        this.point.on('dragend', function (e) {
          console.log('e',e)
          if (document.querySelector('.popup-lat-lng')) {
            // document.getElementById("popup-lat").innerHTML=self.amenityOBJ.lat
            // document.getElementById("popup-lng").innerHTML=self.amenityOBJ.lng
            document.getElementById("popup-lat").innerHTML= e.coordinate.y
            document.getElementById("popup-lng").innerHTML= e.coordinate.x
            self.devEdit = {
              ...self.devEdit,
              lat:e.coordinate.y,
              lng:e.coordinate.x
            }

          }


        })

        self.map.animateTo({
            center: [longitude, latitude],
            zoom: 13,
            pitch: 40,
            bearing: 0
        }, {
            duration: 700
        })
      },
      confirmEdit(){
      if (this.editobj && this.editobj.id === '__dev'){
          console.log('CONFIRM EDIT DEV')
          let self = this
          this.loading = true
          console.log('THIS DEV EDIT', this.devEdit)
          console.log('THIS AMENITYOBJ THEME', this.amenityOBJ.theme)
          let sendObj = JSON.parse(JSON.stringify(this.devEdit))
          sendObj.name = this.editDialogOBJ.devName
          sendObj.theme = {
            type:this.amenityOBJ.theme.type,
            cssFilter:this.amenityOBJ.theme.cssFilter
          }
          console.log('SENDOBJ FROM CONFIRM EDIT DEV', sendObj)
          this.$api.put(`/amenities/${this.instance.id}/${this.appData.id}`,sendObj).then( ({data}) => {
            console.log('DATA COMING BACK', data)
            if (data.id){
              self.onClose({type:'dev',data})
              self.$store.commit('UPDATE_MAIN_MAP')
            }
          }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
      } else {
          console.log('CONFIRM EDIT NOT DEV')
          this.$refs.editDialogOBJ.validate(valid => {
            if (valid) {
              console.log('VALID RAN')
              let self = this
              this.loading = true
              let sendObj = JSON.parse(JSON.stringify(this.editDialogOBJ))
              let editURL = `/amenities/${this.instance.id}/${this.appData.id}/${this.editDialogOBJ.id}`
              if (sendObj.categoryId) editURL = `/amenities/${this.instance.id}/${this.appData.id}/${sendObj.categoryId}/${sendObj.id}`
              delete sendObj.markers
              delete sendObj.devName
              console.log('SEND OBJJ', sendObj)
              this.$api.put(editURL,sendObj).then( ({data}) => {
                console.log('DATAAAAAAAAA COMING CATEGORY UPDATE', data)
                if (data.id){
                  self.onClose({type:'edit',data})
                }
              }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						self.$message.error(self.$err(err))
					}
				})
            } else {
              console.log('error submit!!');
              return false;
            }
          });
          // if (this.editDialogOBJ.name == ''){
          //   this.loading = false
          //   return this.$message.error('Please input category name!')
          // }
        }


      },
      selectIcon(){

        this.$store.commit('MEDIA_SELECT',{callback:this.selectedIcon,type:'images'})
      },
      selectDevIcon(){
        this.$store.commit('MEDIA_SELECT',{callback:this.selectedDevIcon,type:'images'})
      },
      selectedIcon(item){
        this.editDialogOBJ.icon = item.url
        console.log('URLLLLL',item)
      },
      selectedDevIcon(item){
        this.devEdit.logo = item.url
      },
      confirmDelete(){

          this.deleting = true
          this.loading = true

          let deleteURL = `/amenities/${this.instance.id}/${this.appData.id}/${this.editDialogOBJ.id}/all`
          if (this.editDialogOBJ.categoryId) deleteURL = `/amenities/${this.instance.id}/${this.appData.id}/${this.editDialogOBJ.categoryId}/${this.editDialogOBJ.id}`


          this.$api.delete(deleteURL).then( ({data}) => {
			  console.log("editDialogOBJJJ", this.editDialogOBJ)
              this.onClose({type:'delete',data:this.editDialogOBJ})
              // this.$store.dispatch('SET_APPDATA', data)
          }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
      },
      selectedDev(e){
        console.log("EEEEEE",e)
        this.loading = true
        var found = this.dataSource.find(x => x.place_id === e)
        this.placeSelected = found.main_text + ', ' + found.secondary_text
        this.dataSource = []
        console.log('FOUND DATASOURCE', found)
        this.placeChosen = true
        this.processingGoogle = true
        this.location = {}
        let self = this
        this.$api.get(`/amenities/${e}`).then( ({data}) => {
          console.log('APIIII DATAA LOCATION', data)
          self.location = data
          self.loading = false
          self.processingGoogle = false
          console.log('marker geo location', self.marker.geometry.location)
          self.marker.geometry.location = data.geometry.location
          self.putMarker(data.geometry.location.lat,data.geometry.location.lng)
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				self.$message.error(self.$err(err))
			}
		})
      },
      onClose({type='',data={}}) {
          this.$store.dispatch('closeAmenityDrawers',{type,data})
          if (this.$refs.editDialogOBJ) {
            this.$refs.editDialogOBJ.resetFields()
          }
      },
    },
    created(){

    }

  };
</script>

<style scoped>
  .theme-holder-box{
		display: table;
		width: 116px;
		height: 116px;
		text-align: center;
		vertical-align: top;
		border: 1px solid #d8d7d7;
		background-color: #F7F5F9;
		cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
	}
  .theme-holder-box:hover {
      border-color: #9693E7;
      transition: border-color .3s ease;
  }
  .image-holder-box{
      display: table;
      width: 104px;
      height: 104px;
      text-align: center;
      vertical-align: top;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box:hover{
      border-color: var(--orange);
      transition: border-color .3s ease;
  }
  .image-holder-box .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100px;
    height: 100px;
  }
  .image-selector {
	position: absolute;
	z-index: 1000;
	width: 250px;
	margin-top: 75px;
  }
  .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
      background:#3F3356;
  }
</style>

<style lang="scss">
  // .ant-drawer-wrapper-body::-webkit-scrollbar{
  //   display: none;
  // }
  .theme-check-box .ant-checkbox {
      float: right;
      right: -5px;
      z-index: 100;
      &:hover{
          .ant-checkbox-inner{
              border-color: #9693E7;
          }
          .ant-checkbox-checked{
              border-color: #9693E7;
          }
          .ant-checkbox-checked:after{
              border-color: #9693E7;
          }
      }
  }
  .theme-check-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #9693E7;
      border-color: #9693E7;
  }
  .theme-check-box .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #9693E7;
  }
</style>
