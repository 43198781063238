<template>
  <a-popover v-model="visible" title="Preset Icons" trigger="click">
      
      <div slot="content">
          <img @click="sendLogo(index)" v-for="(aImg, index) in markerImages" :key="`markerlogo${index}`" :src="aImg" style="width: 75px; height: 75px;" />
      </div>
      
    <slot />
  </a-popover>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
    components:{ImageBoxSelector},
    data() {
        return {
            visible: false,
            //Place preset icons in markerImages
            markerImages: [
                'https://bildhive.nyc3.digitaloceanspaces.com/96c8782107aa4021b5bb02f4f50c2aa6.png',
                'https://bildhive.nyc3.digitaloceanspaces.com/7ce705321e9b41149e994384000ae2db.png'
            ]
        };
    },
    watch:{
        
    },
    computed: {
        
    },
    methods: {
        sendLogo(id){
            console.log("IMAGE INDEX", id)
            this.$emit('callback', this.markerImages[id])
            this.visible = false
        }
    },
};
</script>