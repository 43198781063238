<template>
	<div class="image-holder-box" style="width: 250px; height: 175px; padding: 0;" @mouseover="showOptions = true" @mouseleave="showOptions = false">
		<div v-if="img === ''" class="mt-9" @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})">
			<ul style="list-style-type: none; padding: 0;">
				<li style="padding-bottom: 30px;"><a-icon type="plus-circle" style="font-size: 30px;" /></li>
				<li>Add Image</li>
			</ul>
		</div>
		<span v-else>
			<div v-show="showOptions" class="image-selector">
				<a-button-group>
					<a-button>Image</a-button>
					<a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:'images'})"><a-icon type="edit" /></a-button>
					<a-button @click="$emit('callback', '')"><a-icon type="delete" /></a-button>
				</a-button-group>
			</div>
			<div v-show="showOptions" style="width: 250px; height: 175px; background-color: rgba(64, 69, 76, 0.5); position: absolute;"></div>
			<div style="background-repeat: no-repeat; background-size: contain; background-position: 50%; width: 246px; height: 171px;" :style="{ 'background-image': 'url(' + img + ')' }" />
		</span>
	</div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
	components:{ImageBoxSelector},
	props: {
		img:{
			default: '',
			type: String
		},
	},
    data() {
        return {
			showOptions: false,
			newImg: ''
        };
    },
    watch:{
        img(){
          console.log('IMAGE CHANGEDDDDDD')
        }
    },
    computed: {
        
    },
    methods: {
		selectedMarkerImage(url){
			this.newImg = url.url
			this.$emit('callback', this.newImg)
		},
    },
};
</script>

<style scoped>
.image-holder-box{
      display: table;
      width: 104px;
      height: 104px;
      text-align: center;
      vertical-align: top; 
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box:hover{
      border-color: var(--orange);
      transition: border-color .3s ease;
  }
  .image-holder-box .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100px;
    height: 100px;
  }
  .image-selector {
	position: absolute;
	z-index: 1000;
	width: 250px;
	margin-top: 75px;
  }
</style>