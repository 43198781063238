var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '20px 26px',
          background: '#fff',
          textAlign: 'right',
        })},[_c('a-button',{staticClass:"cancel-button",staticStyle:{"width":"180px","height":"40px"},style:({marginRight: '20px'}),on:{"click":function($event){return _vm.$emit('cancel', '')}}},[_vm._v(" CANCEL ")]),_c('a-button',{staticClass:"button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('submit', '')}}},[_vm._v(_vm._s(_vm.edit? "SAVE":"CREATE"))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }