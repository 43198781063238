<template>
	<div
        :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '20px 26px',
          background: '#fff',
          textAlign: 'right',
        }"
      >
        <a-button style="width:180px; height:40px" :style="{marginRight: '20px'}" class="cancel-button" @click="$emit('cancel', '')">
          CANCEL
        </a-button>
        <a-button @click="$emit('submit', '')" class="button" type="primary">{{edit? "SAVE":"CREATE"}}</a-button>
      </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
	components:{ImageBoxSelector},
	props: {
    edit:{
      default:false,
      type:Boolean
    },
		img:{
			default: '',
			type: String
		},
	},
    data() {
        return {
			showOptions: false,
			newImg: ''
        };
    },
    watch:{
        
    },
    computed: {
        
    },
    methods: {
		selectedMarkerImage(url){
			this.newImg = url.url
			this.$emit('callback', this.newImg)
		},
    },
};
</script>

<style scoped>
.button {
	width: 180px;
	height: 40px;
}
</style>
<style>
  .cancel-button.ant-btn {
      border-color:#ECE9F1 !important;
      background-color:#ECE9F1; 
      color:#3F3356
  }
</style>